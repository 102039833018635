import { Col, Container, Row } from "react-bootstrap";
import { PromoOne as Promo } from "@components/promo";
import { CategoryOne as Category } from "@components/category";


const CategoriesTwo = ({ className }) => {
  return (
    <div className={`container-indent0 ${className ? className : ""}`}>
      <Container>
        <Row className="flex-sm-row-reverse tt-layout-promo-box">
          <Col md={6}>
            <Promo
              title=""
              heading="Living"
              thumb="/assets/images/categories/living-thumb.jpg"
              link="/living"
            />
            <Promo
              title=""
              heading="Cocina"
              thumb="/assets/images/banner/home/cocina.png"
              link="/kitchen"
            />
          </Col>
          <Col md={6}>
            <Promo
              title=""
              heading="BAÑOS"
              thumb="/assets/images/categories/bath-1060.png"
              link="/bath"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesTwo;
