import Masonry from "react-masonry-component";
import ProductLookbook from "@components/product/ProductLookbook";
import { Container } from "react-bootstrap";
import SectionTitle from "@components/ui/section-title";
import { useContext } from "react";
import { ProductGroupsContext } from "@global/ProductGroups";

const LookbookProducts = ({ className }) => {
  const { productGroups } = useContext(ProductGroupsContext);

  if (!productGroups.length) {
    return <></>;
  }

  return (
    <div className="container-indent0">
      <Container>
        <SectionTitle title="DESTACADOS" content="EJEMPLOS" />
        <div className={`container-indent ${className ? className : ""}`}>
          <div className=" container-fluid-custom-mobile-padding">
            <div className="tt-lookbook-masonry">
              <Masonry className="tt-lookbook-init tt-grid-col-3 tt-add-item tt-show">
                {productGroups.map((productGroup) => (
                  <div  className="element-item">
                    <ProductLookbook productGroup={productGroup} />
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LookbookProducts;
