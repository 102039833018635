import { gql} from "@apollo/client";


const promotionQuery = gql`
  query {
    promotions {
      data {
        id
        attributes {
          imagebanner {
            data {
              id
              attributes {
                url
              }
            }
          }
          products {
            data {
              id
              attributes {
                title
                images {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export default promotionQuery;