import { CartContext } from "@global/CartContext";
import { useWindowSize } from "@hooks";
import { CURRENCY } from "@utils/constant";
import { getClosest } from "@utils/method";
import { useContext } from "react";

const ProductLookbook = ({ productGroup }) => {
  const { addToCart } = useContext(CartContext);
  const onHotspotOpenHandler = (e) => {
    const target = e.target;
    const ttHotspot = getClosest(target, ".tt-hotspot");
    target.nextSibling.classList.add("show");
    ttHotspot.classList.add("active");
  };

  const onHotspotCloseHandler = (e) => {
    const target = e.target;
    const ttHotspot = getClosest(target, ".tt-hotspot");
    target.nextSibling.classList.remove("show");
    ttHotspot.classList.remove("active");
  };

  return (
    <div className="tt-lookbook">
      <img src={productGroup.thumb} alt="lookbook-wokiee" />
      {productGroup.products.map((pro, i) => {
        return (
          <div
            key={pro.product.id}
            className={`tt-hotspot`}
            data-top={pro.top}
            data-left={pro.left}
            style={{ top: pro.top, left: pro.left }}
          >
            <div
              className="tt-btn"
              onClick={(event) => onHotspotOpenHandler(event)}
            />
            <div className="tt-content-parent">
              <div className="tt-hotspot-content">
                <button
                  className="tt-btn-close"
                  onClick={(event) => onHotspotCloseHandler(event)}
                />
                <div className="tt-img">
                  <a href={`/product/${pro.product.slug.toLowerCase()}`}>
                    <img src={pro.product.thumb} alt={pro.product.name} />
                  </a>
                </div>
                <div className="tt-description">
                  <h2 className="tt-title">
                    <a href={`/product/${pro.product.slug.toLowerCase()}`}>
                      {pro.product.name}
                    </a>
                  </h2>
                  <div className="tt-price">{CURRENCY + ' ' + pro.product.price}</div>
                  <button
                    className="btn"
                    onClick={() =>
                      addToCart({
                        ...pro.product,
                        price: pro.product.price,
                        color: pro.product.color,
                        size: pro.product.size,
                        material: pro.product.material,
                        quantity: 1,
                      })
                    }
                  >
                    AGREGAR AL CARRITO
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ProductLookbook;
